import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FiriaLabsBanner from './firialabsbanner.png'
import { Typography, Button, Chip, Divider } from '@material-ui/core'
import { FiriaGreenButton } from './buttons'
import { redirectToLoginSite } from './LoginContext'

const useStyles = makeStyles(theme => ({
  logoutPageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  firiaBanner: {
    display: 'flex',
    alignItems:'center',
  },
  sessionContainer: {
    width: 800,
    padding: '30px 60px',
    borderRadius: 10,
    backgroundColor: 'rgba(0,0,0,0.025)',
    border: '1px solid rgba(0,0,0,0.15)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  topHalfContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    padding: 15,
    paddingBottom: 20,
  },
  dividerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  bottomButtonContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    padding: 15,
    paddingTop: 20,
    flexDirection: 'column',
  },
}))


function VerticalLoginButtonsContainer(props) {
  const classes = useStyles()

  return (
    <div style={{ width: '100%'}}>
      <div className={classes.topHalfContainer}>
        {props.children[0]}
      </div>
      <div className={classes.dividerContainer}>
        <Divider className={classes.divider}/>
        <Chip label='OR' />
        <Divider className={classes.divider}/>
      </div>
      <div className={classes.bottomButtonContainer}>
        {props.children[1]}
      </div>
    </div>
  )
}

function UserSessionOverwrittenPage() {
  const classes = useStyles()

  function refresh() {
    window.location.href = '/'
  }

  const BUTTON_WIDTH = 280
  return (
    <div className={classes.logoutPageContainer}>
      <img alt={''} className={classes.firiaBanner} src={FiriaLabsBanner}/>
      <div className={classes.sessionContainer} >
        <Typography variant={'h5'}>{'Your account has been logged into on another device.'}</Typography>
        <Typography style={{paddingTop: 10, paddingBottom: 20}}>{'You\'ve been logged out on this device!'}</Typography>
        <VerticalLoginButtonsContainer>
          <FiriaGreenButton onClick={redirectToLoginSite} variant='contained' style={{ width: BUTTON_WIDTH }} >
            {'Log In'}
          </FiriaGreenButton>
          <Button style={{ width: BUTTON_WIDTH }} variant='outlined' onClick={refresh}>
            {'Continue without logging in'}
          </Button>
        </VerticalLoginButtonsContainer>
      </div>
    </div>
  )
}

export { UserSessionOverwrittenPage }