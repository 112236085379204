
const CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID = {
  CTE: [
    {missionId: 'Free_03LightTheWay', flowIds: ['Free_03LightTheWay_03o','Free_03LightTheWay_05o']},
    {missionId: 'Free_04GetMoving', flowIds: ['Free_04GetMoving_03o']},
    {missionId: 'CTE_05DanceBot', flowIds: ['CTE_05DanceBot_04o', 'CTE_05DanceBot_07o']},
    {missionId: 'CTE_01Metronome', flowIds: ['CTE_01Metronome_04o', 'CTE_01Metronome_08o', 'CTE_01Metronome_10o']},
    {missionId: 'CTE_02LineSensors', flowIds: ['CTE02LineSensors_03o', 'CTE02LineSensors_06o']},
    {missionId: 'CTE_BoundaryPatrol', flowIds:['CTE_BoundaryPatrol_04o', 'CTE_BoundaryPatrol_06o']},
    {missionId: 'CTE_LineFollowing', flowIds: ['CTE_LineFollowing_04o', 'CTE_LineFollowing_055o', 'CTE_LineFollowing_07o']},
    {missionId: 'CTE_FidoFetch', flowIds:['CTE_FidoFetch_03o', 'CTE_FidoFetch_09o']},
    {missionId: 'CTE_RunwayOps', flowIds: ['CTE_RunwayOps_03o', 'CTE_RunwayOps_06o']},
    {missionId: 'CTE_RockClimber', flowIds: ['CTE_RockClimber_02_1o', 'CTE_RockClimber_05o']},
    {missionId: 'CTE_GoingTheDistance', flowIds: ['CTE_TheDistance_06o', 'CTE_TheDistance_11o']},
    {missionId: 'CTE_MusicBox', flowIds: ['CTE_MusicBox_o05', 'CTE_MusicBox_o10']},
    // {missionId: 'CTE_CyberStorm', flowIds: ['CTE_CyberStorm_05o', 'CTE_CyberStorm_09o']},
  ],
  LCX: [
    {missionId: 'LCX_WelcomeToCodeX', flowIds: ['LCX_WelcomeToCodeX_09o']},
    {missionId: 'LCX_LightShow', flowIds: ['LCX_LightShow_07o', 'LCX_LightShow_09o']},
    {missionId: 'LCX_DisplayGames', flowIds: ['LCX_DisplayGames_06o', 'LCX_DisplayGames_10o']},
    {missionId:  'LCX_MicroMusician', flowIds: ['LCX_MicroMusician_o06']},
    {missionId: 'LCX_Heartbeat', flowIds: ['LCX_Heartbeat_o07', 'LCX_Heartbeat_o11']},
    {missionId: 'LCX_PersonalBillboard', flowIds: ['LCX_PersonalBillboard_o03', 'LCX_PersonalBillboard_o06', 'LCX_PersonalBillboard_o09']},
    {missionId: 'LCX_AnswerBot', flowIds: ['LCX_AnswerBot_o04', 'LCX_AnswerBot_o07']},
    {missionId: 'LCX_GameSpinner', flowIds: ['LCX_GameSpinner_o04', 'LCX_GameSpinner_o08']},
    {missionId: 'LCX_ReactionTester', flowIds: ['LCX_ReactionTester_o04', 'LCX_ReactionTester_o07']},
    {missionId: 'LCX_SpiritLevel', flowIds: ['LCX_SpiritLevel_o06']},
    {missionId: 'LCX_NightLight', flowIds: ['LCX_NightLight_o05']},
  ],
}

export { CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID }