import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import LoadingErrorPage from '../common/LoadingErrorPage'
import CustomerSupportFab from '../common/CustomerSupportFab'
import { setErrorCb } from '../common/utils'
import { UserSessionOverwrittenPage } from '../common/UserSessionOverwrittenPage'
import { useLogin } from '../common/LoginContext'

import { ClassDashboardSiteHeader } from './ClassDashboardSiteHeader'
import { Outlet } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  appRoot: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    textAlign: 'center',
    backgroundColor: theme.palette.custom.background,
  },
}))



function RootContent() {
  const classes = useStyles()
  const [loginState] = useLogin()
  const [errorState, setErrorState] = React.useState(false)
  setErrorCb(()=>setErrorState(true))

  if (loginState.userSessionOverwritten) {
    return <UserSessionOverwrittenPage />
  }

  return (
    <>
      {errorState ? <LoadingErrorPage />:
        <div className={classes.appRoot}>
          <ClassDashboardSiteHeader />
          <div style={{flexGrow: 1}}>
            <Outlet />
          </div>
        </div>
      }
      <CustomerSupportFab />
    </>
  )
}

function Root() {
  return (
    <>
      <CssBaseline />
      <RootContent />
    </>
  )
}

export { Root }